import 'zone.js/plugins/zone-error'; // Included with Angular CLI.

export const environment = {
  production: true,
  environment: 'prod',
  baseUrl: window.location.origin + '/',
  contentUrl: 'https://{subdomain-}backend.{domain}.{loc}/',
  apiUrl: 'https://{subdomain-}backend.{domain}.{loc}/',
  //apiUrl: 'https://sgm-backend.arte.pl/',
  blogUrl: 'https://blog.{domain}.{loc}',
  imageUrl: 'https://images.{domain}.{loc}',
  userIdKey: 'predictionUserId',
  tokenKey: 'CustomerKey',
  guidKey: 'GuidKey',
  googleMapAPIKey: 'AIzaSyBd75yw_6KmY-8C6xwnUvqJOmB7d4lbq4w',
  facebookAppId: '726320725570117',
  googlePlusClientId: '6546764459-6j9m497cdm7j4993iuosn0abpn8scger.apps.googleusercontent.com',
  defaultUrlListing: 'a=1&p=1&c=60&q=*',
  instrumentation: {
    output: {
      rollbar: {
        enabled: true,
        accessToken: '184087e27c004dcba44364301f2d9995',
        captureUncaught: true,
        captureUnhandledRejections: true,
        itemsPerMinute: 30,
        maxItems: 100,
        payload: {
          code_version: '24-11-14 15:08',
          environment: 'production',
          source_map_enabled: true,
          guess_uncaught_frames: true,
        }
      }
    }
  },

  analytics: {
    ga4Analytics: true,
    googleAnalytics: true,
    edroneAnalytics: false,
    googleAdsAnalytics: true,
    bingAdsAnalytics: false,
    facebookAnalytics: false,

    Ga4AnalyticsServiceDelay: 1500,
    GoogleAdsAnalyticsServiceDelay: 1,
    EdroneAnalyticsServiceDelay: 3000,
    BingAdsAnalyticsServiceDelay: 1500,
    FacebookAnalyticsServiceDelay: 1500,

    edroneAppID: "?",
    bingTagID: "?",
    facebookPixelID: "?",


    googleAdsPropertyID: "AW-16766599305",
    googleAdsConversionID: "AW-16766599305/MgsqCKSvmeoZEImB-Lo-",
    googleGA4PropertyID: "G-ZPF7TKEYPS",
    googleAdsAndGA4: "AdsAndGA4"
  },

  DEFAULT_PRODUCTS_PER_PAGE: 60,
  DEFAULT_PRODUCTS_PER_PAGE_MOBILE: 30,

  instalments: false,
  instalmentsDeley: 200,

  intercom: true,
  IntercomDelay: 3000,

  rollbar: true,
  rollbarDelay: 1500,

  intercomeDataSettings: {
    app_id: 'ffwyw47n',
    user_id: '',
    widget: { 'activator': '#intercom' }
  },

  canBuyWithInstallment: false,
  frontendVersion: 1
};